import type { BackgroundColorsPredefined } from '~/@types/cms';

export const useStyle = (prefix = 'bg') => {
    const colorMapper: Record<Partial<BackgroundColorsPredefined>, string> = {
        default: '',
        custom: `${prefix}-[--custom-background-color]`,
        black: `${prefix}-woom-black`,
        dark: `${prefix}-woom-black`,
        grey: `${prefix}-woom-grey`,
        mossgreen: `${prefix}-woom-green-moss`,
        light: `${prefix}-white`,
        white: `${prefix}-white`,
    };
    return { colorMapper };
};
